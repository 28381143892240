import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ResourceModel } from '../models/resource/resource.model';
import { convertToDate } from '../helpers/commonHelpers';

export const PACKAGE_TYPE = 'type-package';

export const resourceTypes = {
  'type-package': { id: 'type-package', title: 'Package' },
  'type-doc': { id: 'type-docs', title: 'Doc' },
  'type-slides': { id: 'type-slides', title: 'Slides' },
  'type-pdf': { id: 'type-pdf', title: 'PDF' },
  'type-video': { id: 'type-video', title: 'Video' },
};

export const resourceTypesArr = Object.values(resourceTypes);

export const urlRegExp = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d]%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
);

export const resourceTableColumns: GridColDef<ResourceModel>[] = [
  { field: 'title', headerName: 'Title', width: 400 },
  {
    field: 'type',
    headerName: 'Type',
    width: 110,
    valueGetter: (params: GridValueGetterParams<any, ResourceModel>) => {
      return params.row.docType;
    },
  },
  { field: 'category', headerName: 'Categories', width: 140 },
  { field: 'standalone', headerName: 'Standalone', width: 140 },
  {
    field: 'published',
    headerName: 'Status',
    width: 140,
    renderCell(props) {
      const date = props.row.publishedDate.toDate();
      return (
        <div className="column-publish">
          <span>{props.value}</span>
          <span>{convertToDate(date)}</span>
        </div>
      );
    },
  },
  {
    field: 'updateAt',
    headerName: 'Last Update',
    width: 140,
    renderCell(props) {
      try {
        const date = props.row.updateAt.toDate();
        return convertToDate(date);
      } catch (err) {
        return '';
      }
    },
  },
];
