import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import DeleteIcon from '@mui/icons-material/Delete';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';
import Container from '@mui/material/Container';
import { ResourceModel } from '../../../models/resource/resource.model';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router/routes';
import { FirestoreApi } from '../../../service/firestoreApi';
import { useGetResCategories, useResListQuery } from '../../../hooks/resource/resource';
import { useDataTable } from '../../../hooks/table/table';
import { BackdropSpinner } from '../../controls';
import Button from '@mui/material/Button';
import { resourceTableColumns } from '../../../constants';
import { collectResourcesByIds } from '../../../helpers/resourceHelpers';
import { fetchSignOut } from '../../../store/user/user';
import { useAppDispatch } from '../../../hooks/global';
import AlertDialogSlide from '../../common/AlertDialogSlide/AlertDialogSlide';

const ResourceList = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ResourceModel[] | null>(null);
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const { selectedIds, handleChangeSelection } = useDataTable();
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  useGetResCategories();
  const { setQuery, query } = useResListQuery();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    let data = await FirestoreApi.getResources();
    if (data) {
      setLoading(false);
      setData(data);
    }
  };

  const handleRowClick = (row: GridRowParams<ResourceModel>) => {
    navigate(`${ROUTES.RESOURCE_FORM.replace(':key', String(row.id))}`, {
      state: row.row,
    });
  };

  const handleLogout = () => {
    dispatch(fetchSignOut());
  };

  const onDelete = async () => {
    setLoading(true);
    await FirestoreApi.deleteResources(
      collectResourcesByIds(selectedIds as string[], data),
    );
    await fetchData();
    setLoading(false);
    setIsOpenModal(false);
  };

  if (!data) return <BackdropSpinner />;

  return (
    <div>
      {loading && <BackdropSpinner />}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Container maxWidth="lg" component="main">
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            component={RouterLink}
            to={ROUTES.RESOURCE_FORM.replace(':key', 'create')}
            className="link-action"
            variant="text"
            startIcon={<AddIcon />}
          >
            Add Resource
          </Button>
          <Button
            sx={{
              marginLeft: 2,
            }}
            disabled={!selectedIds.length}
            className="link-action"
            onClick={() => setIsOpenModal(true)}
            variant="text"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ height: window.innerHeight - 180, width: '100%' }}>
            <DataGrid
              getRowId={row => {
                return row.id;
              }}
              onSortModelChange={sort => setQuery({ sort })}
              sortModel={query.sort}
              onPageChange={page => setQuery({ page })}
              page={query.page}
              onRowClick={handleRowClick}
              rows={data}
              columns={resourceTableColumns}
              onSelectionModelChange={handleChangeSelection}
              pageSize={query.pageSize}
              checkboxSelection
              onPageSizeChange={pageSize => setQuery({ pageSize })}
              rowsPerPageOptions={[5, 10, 20]}
              disableColumnFilter
              // paginationMode
            />
          </div>
        </Box>
      </Container>
      <AlertDialogSlide
        open={isOpenModal}
        setOpen={setIsOpenModal}
        pressSave={onDelete}
        title={'Delete Resource'}
        content={
          <div className="column-publish">
            <span>The delete action cannot be undone.</span>
            <span>
              Note: You have the option to no longer make the resource publicly available
              and keep it in the database by editing the resource.
            </span>
          </div>
        }
      />
    </div>
  );
};

export default ResourceList;
