import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from './routes';
import { SignIn, ResourceList, ResourceForm } from '../components/pages';
import { useAppSelector } from '../hooks/global';

const Router = () => {
  const accessToken = useAppSelector(state => state.user.accessToken);

  if (accessToken) {
    return (
      <Routes>
        <Route path={ROUTES.RESOURCE_LIST} element={<ResourceList />} />
        <Route path={ROUTES.RESOURCE_FORM} element={<ResourceForm />} />
        <Route path="*" element={<Navigate to={ROUTES.RESOURCE_LIST} replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
      <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} replace />} />
    </Routes>
  );
};

export default Router;
