import { useState } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';

export const useDataTable = () => {
  let [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);
  const handleChangeSelection = (selectedIds: GridSelectionModel) => {
    setSelectedIds(selectedIds);
  };

  return {
    selectedIds,
    handleChangeSelection,
  };
};
