import React, { FC, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { resourceTableColumns } from '../../../../constants';
import { useDataTable } from '../../../../hooks/table/table';
import { FirestoreApi } from '../../../../service/firestoreApi';
import { ResourceModel } from '../../../../models/resource/resource.model';
import { collectResourcesByIds } from '../../../../helpers/resourceHelpers';
import { BackdropSpinner } from '../../../controls';
import Typography from '@mui/material/Typography';

interface IProps {
  onSubmit: (newSelectedResources: ResourceModel[]) => void;
  selectedResources: ResourceModel[];
  onClose: () => void;
}

const AddResourceList: FC<IProps> = ({ onSubmit, onClose, selectedResources }) => {
  const { selectedIds, handleChangeSelection } = useDataTable();
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [data, setData] = useState<ResourceModel[] | null>(null);

  useEffect(() => {
    FirestoreApi.getAllSingleResources().then(res => {
      setData(res);
      handleChangeSelection(selectedResources.map(item => item.id));
    });
  }, []);

  const handleSubmit = () => {
    onSubmit(collectResourcesByIds(selectedIds as string[], data));
    onClose();
  };

  if (!data) return <BackdropSpinner />;

  return (
    <div>
      <Container maxWidth="md" component="main">
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <Typography fontWeight={500} component="h1" variant="h5">
            Select Resources
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <div style={{ height: window.innerHeight - 170, width: '100%' }}>
            <DataGrid
              getRowId={row => {
                return row.id;
              }}
              rows={data}
              selectionModel={selectedIds}
              columns={resourceTableColumns}
              onSelectionModelChange={handleChangeSelection}
              pageSize={pageSize}
              checkboxSelection
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              // paginationMode
            />
          </div>
        </Box>
        <Box
          sx={{
            margin: '0 auto 30px',
            maxWidth: 350,
          }}
        >
          <Grid container spacing={4}>
            <Grid alignSelf="flex-end" item xs={6}>
              <Button
                onClick={onClose}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid alignSelf="flex-end" item xs={6}>
              <Button
                onClick={handleSubmit}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default AddResourceList;
