import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropSpinner = () => {
  return (
    <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress />
    </Backdrop>
  );
};

export default BackdropSpinner;
