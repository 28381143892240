import { ResourceFormModel, ResourceModel } from '../models/resource/resource.model';

export const generateSearchFields = (data: ResourceFormModel): string[] => {
  const res: string[] = [];
  const add = (word: string) => {
    if (word) {
      res.push(word);
    }
  };
  data.title.split(' ').forEach(word => {
    add(word);
  });
  data.description.split(' ').forEach(word => {
    add(word);
  });
  data.tags.split(' ').forEach(word => {
    add(word);
  });
  return res;
};

export const filterResourceByOriginID = (data: ResourceModel[]) => {
  return data.filter(item => item.originalID);
};

export const collectResourcesByIds = (
  ids: string[],
  data: ResourceModel[] | null,
): ResourceModel[] => {
  let result: ResourceModel[] = [];

  if (data) {
    ids.forEach(id => {
      data.forEach(item => {
        if (item.id === id) {
          result.push(item);
        }
      });
    });
  }

  return result;
};
