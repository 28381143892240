export const roundNumber = (val: string | number, multiplier: number = 100): number => {
  return Math.round(Number(val) * multiplier) / multiplier;
};

export const isEmptyObject = (obj: object | null) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const generateHash = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const cutString = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
};

export const onlyUnique = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index;
};

export const convertToDate = (date: any) => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // month is zero-based
  let dd = date.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return mm + '.' + dd + '.' + yyyy;
};
