import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAfjflIeTCuMI2ulaKZUSTOuMUtah0zY28',
  authDomain: 'in-resourceguide.firebaseapp.com',
  databaseURL: 'https://in-resourceguide.firebaseio.com',
  storageBucket: 'in-resourceguide.appspot.com',
  projectId: 'in-resourceguide',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
