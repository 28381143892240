import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SignInModel } from '../../models/user/user.model';
import { AuthApi } from '../../service/authApi';
import { toast } from 'react-toastify';

export const fetchSignIn = createAsyncThunk('user/signIn', async (form: SignInModel) => {
  return await AuthApi.signIn(form);
});

export const fetchSignOut = createAsyncThunk('user/SignOut', async () => {
  await AuthApi.signOut();
});

interface UserState {
  accessToken: string | null;
  email: string | null;
}

const initialState: UserState = {
  accessToken: localStorage.getItem('accessToken') || null,
  email: null,
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchSignIn.fulfilled, (state, action) => {
      state.email = action.payload.email;
      state.accessToken = action.payload.accessToken;
      localStorage.setItem('accessToken', action.payload.accessToken);
    });
    builder.addCase(fetchSignIn.rejected, (state, action) => {
      toast.error('You have entered an invalid email or password.');
    });
    builder.addCase(fetchSignOut.fulfilled, (state, action) => {
      state.accessToken = null;
      localStorage.removeItem('accessToken');
    });
    builder.addCase(fetchSignOut.rejected, (state, action) => {
      toast.error(action.error.message);
    });
  },
});

// export const { login } = authSlice.actions;

export default user.reducer;
