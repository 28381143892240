import React, { useEffect, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/AddCircle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { FirestoreApi } from '../../../service/firestoreApi';
import { PACKAGE_TYPE, resourceTypesArr } from '../../../constants';
import {
  ResourceCreateModel,
  ResourceFormModel,
  ResourceModel,
} from '../../../models/resource/resource.model';
import { useGetResCategories } from '../../../hooks/resource/resource';
import { BackdropSpinner } from '../../controls';
import AddResourceList from './parts/AddResourceList';
import { generateSearchFields } from '../../../helpers/resourceHelpers';
import { KeyValueModel } from '../../../models/global';
import { Entity } from '../../../constants/api';
// import { urlRegExp } from '../../../constants';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { fetchSignOut } from '../../../store/user/user';
import { useAppDispatch } from '../../../hooks/global';

const ResourceForm = () => {
  const { key } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { categories } = useGetResCategories();

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    unregister,
    formState: { errors },
  } = useForm<ResourceFormModel>({
    defaultValues: {
      title: '',
      description: '',
      category: '',
      tags: '',
      type: '',
      url: '',
      utubeLink: '',
      order: '',
      standalone: false,
      published: false,
    },
  });
  let [dataLoaded, setDataLoaded] = useState<ResourceModel | null>(null);
  const [resPackage, setResPackage] = useState<ResourceModel[]>([]);
  let [loading, setLoading] = useState(false);
  let [addMode, setAddMode] = useState(false);
  const [removed, setRemoved] = useState<KeyValueModel<string>>({});

  const watchType = watch('type');
  const isCreate = key === 'create';
  const isPackage = watchType === PACKAGE_TYPE;

  useEffect(() => {
    if (!isCreate && categories && !dataLoaded) {
      fetchData();
    }
  }, [categories]);

  const fetchData = async () => {
    if (key) {
      const response = await FirestoreApi.getOne<ResourceModel>(Entity.RESOURCE, key);
      if (response && categories) {
        const isPackageLocal = response.packageDocs.length;

        if (isPackageLocal) {
          const packageItems = await FirestoreApi.getResourcesByParentId(
            response.originalID,
          );
          if (packageItems) {
            setResPackage(packageItems);
          }
        }

        setValue('title', response.title);
        setValue('description', response.description);
        setValue('tags', response.tags);
        setValue('url', response.link);
        setValue('utubeLink', response.utubeLink);
        setValue('order', String(response.orderPriority));
        setValue('standalone', response.standalone === 'Yes');
        setValue('published', response.published === 'Published');
        setValue(
          'category',
          categories.find(item => item.name === response.category)?.categoryID || '',
        );
        setValue(
          'type',
          resourceTypesArr.find(item => item.id === response.docType)?.id || '',
        );
        setDataLoaded(response);
      }
    }
  };

  const handleChangeType = (handler: any) => {
    if (handler.target.value === PACKAGE_TYPE) {
      setValue('url', '');
      setValue('utubeLink', '');
      setValue('standalone', true);
      unregister('url');
    } else {
      setResPackage([]);
    }
  };

  const getPublishDate = (data: ResourceFormModel) => {
    if (isCreate || !dataLoaded) {
      return Timestamp.fromDate(new Date());
    }
    if (dataLoaded.published !== (data.published ? 'Published' : 'Not Published')) {
      return Timestamp.fromDate(new Date());
    } else {
      return dataLoaded.publishedDate;
    }
  };

  const _convertData = (data: ResourceFormModel): ResourceCreateModel => {
    const result: ResourceCreateModel = {
      title: data.title,
      description: data.description,
      category: categories?.find(item => item.categoryID === data.category)?.name || '',
      docType: resourceTypesArr.find(item => item.id === data.type)?.id || '',
      link: data.url || '',
      utubeLink: data.utubeLink,
      orderPriority: Number(data.order),
      published: data.published ? 'Published' : 'Not Published',
      standalone: data.standalone ? 'Yes' : 'No',
      tags: data.tags,
      searchField: generateSearchFields(data),
      publishedDate: getPublishDate(data),
      packageDocs: resPackage.map(item => item.originalID),
      parentResource: 0,
      updateAt: Timestamp.fromDate(new Date()),
    };
    if (isCreate) {
      result.createAt = Timestamp.fromDate(new Date());
    }
    if (!isCreate && dataLoaded?.originalID) {
      result.originalID = dataLoaded.originalID;
      result.parentResource = dataLoaded.parentResource;
    }
    return result;
  };

  const handleClickAddResource = () => {
    setAddMode(true);
  };

  const handleAddResource = (data: ResourceModel[]) => {
    setResPackage([...resPackage, ...data]);
  };

  const handleDeleteResource = (id: string) => {
    setRemoved(prevState => {
      prevState[id] = id;
      return {
        ...prevState,
      };
    });
    setResPackage(resPackage.filter(item => item.id !== id));
  };

  const onSubmit = async (form: ResourceFormModel) => {
    const data = _convertData(form);
    setLoading(true);
    if (isCreate) {
      FirestoreApi.createResource({
        data,
        packageItems: data.packageDocs.length ? resPackage : undefined,
        callback: () => {
          navigate(-1);
        },
      });
    } else {
      const status = await FirestoreApi.updateResource({
        data,
        id: String(key),
        packageItems: data.packageDocs.length ? resPackage : undefined,
        removedItemIds: Object.keys(removed),
      });
      if (status) {
        navigate(-1);
      }
    }
  };

  const handleLogout = () => {
    dispatch(fetchSignOut());
  };

  if (!categories) return null;

  if (addMode) {
    return (
      <AddResourceList
        onClose={() => setAddMode(false)}
        onSubmit={handleAddResource}
        selectedResources={resPackage}
      />
    );
  }

  return (
    <>
      {loading && <BackdropSpinner />}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Container component="main" maxWidth={'md'}>
        <Box
          sx={{
            marginTop: 8,
          }}
        >
          <Typography fontWeight={500} component="h1" variant="h5">
            {isCreate ? 'Create' : 'Update'} Resource
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 4,
          }}
        >
          <Grid container spacing={4}>
            <Grid alignSelf="center" item xs={4}>
              Title
            </Grid>
            <Grid alignSelf="flex-end" item xs={8}>
              <Controller
                rules={{ required: 'This field is required' }}
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="title"
                    fullWidth
                    label="Title of the resource"
                    variant="standard"
                    error={Boolean(errors.title)}
                    helperText={errors.title?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid alignSelf="center" item xs={4}>
              Description
            </Grid>
            <Grid alignSelf="flex-end" item xs={8}>
              <Controller
                rules={{ required: 'This field is required' }}
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    id="description"
                    label="Short Description"
                    variant="standard"
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: 6,
            maxWidth: 420,
          }}
        >
          <FormControl fullWidth error={Boolean(errors.category)}>
            <InputLabel id="cat-label">Category</InputLabel>
            <Controller
              name="category"
              rules={{ required: 'This field is required' }}
              control={control}
              render={({ field }) => (
                <Select labelId="cat-label" id="cat" label="Category" {...field}>
                  {categories.map(cat => {
                    return (
                      <MenuItem key={`cat-${cat.categoryID}`} value={cat.categoryID}>
                        {cat.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            <FormHelperText>{errors.category?.message}</FormHelperText>
          </FormControl>
        </Box>
        <Box
          sx={{
            marginTop: 4,
          }}
        >
          <Grid container spacing={4}>
            <Grid alignSelf="center" item xs={4}>
              Tags:
            </Grid>
            <Grid alignSelf="flex-end" item xs={8}>
              <Controller
                rules={{ required: 'This field is required' }}
                name="tags"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    id="tags"
                    label="List of tags (separated by ,)"
                    variant="standard"
                    error={Boolean(errors.tags)}
                    helperText={errors.tags?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: 6,
            maxWidth: 420,
          }}
        >
          <FormControl fullWidth error={Boolean(errors.type)}>
            <InputLabel id="resource-type-label">Resource Type</InputLabel>
            <Controller
              rules={{ required: 'This field is required', onChange: handleChangeType }}
              name="type"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="resource-type-label"
                  id="resource-type"
                  label="Resource Type"
                  {...field}
                >
                  {resourceTypesArr.map(item => {
                    return (
                      <MenuItem key={`type-${item.id}`} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            <FormHelperText>{errors.type?.message}</FormHelperText>
          </FormControl>
        </Box>
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 10,
          }}
        >
          <Grid container spacing={4}>
            {isPackage ? (
              <>
                <Grid alignSelf="flex-start" item xs={4}>
                  Documents
                </Grid>
                <Grid alignSelf="flex-end" item xs={8}>
                  {resPackage.map(item => {
                    return (
                      <Grid container paddingBottom={1} key={`pac-item-${item.id}`}>
                        <Grid alignSelf="center" item xs={10}>
                          {item.title}
                        </Grid>
                        <Grid alignSelf="center" item xs={2}>
                          <IconButton
                            onClick={() => handleDeleteResource(item.id)}
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Button
                    className="link-action"
                    onClick={handleClickAddResource}
                    variant="text"
                    startIcon={<AddIcon />}
                  >
                    Add Document
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid alignSelf="center" item xs={4}>
                  Document Link:
                </Grid>
                <Grid alignSelf="flex-end" item xs={8}>
                  <Controller
                    rules={{
                      required: 'This field is required',
                      // pattern: {
                      //   value: urlRegExp,
                      //   message: 'Please enter valid url'
                      // }
                    }}
                    name="url"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="url"
                        label="Document URL"
                        variant="standard"
                        error={Boolean(errors.url)}
                        helperText={errors.url?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid alignSelf="center" item xs={4}>
                  Youtube Link:
                </Grid>
                <Grid alignSelf="flex-end" item xs={8}>
                  <Controller
                    // rules={{
                    //   pattern: {
                    //     value: urlRegExp,
                    //     message: 'Please enter valid url'
                    //   }
                    // }}
                    name="utubeLink"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="utubeLink"
                        label="Youtube URL"
                        variant="standard"
                        error={Boolean(errors.utubeLink)}
                        helperText={errors.utubeLink?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid alignSelf="center" item xs={4}>
              Order Priority:
            </Grid>
            <Grid alignSelf="flex-end" item xs={8}>
              <Controller
                rules={{
                  required: 'This field is required',
                  min: { value: 1, message: 'This field must be at least 1' },
                  max: { value: 10, message: 'This field must be less than 10' },
                }}
                name="order"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    id="order"
                    type={'number'}
                    label="Order 1 thru 10"
                    variant="standard"
                    error={Boolean(errors.order)}
                    helperText={errors.order?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            {!isPackage ? (
              <>
                <Grid alignSelf="center" item xs={4}>
                  Standalone Document:
                </Grid>
                <Grid alignSelf="flex-end" item xs={8}>
                  <Controller
                    name="standalone"
                    control={control}
                    render={({ field }) => <Checkbox checked={field.value} {...field} />}
                  />
                </Grid>
              </>
            ) : null}
            <Grid alignSelf="center" item xs={4}>
              Published:
            </Grid>
            <Grid alignSelf="flex-end" item xs={8}>
              <Controller
                name="published"
                control={control}
                render={({ field }) => <Checkbox checked={field.value} {...field} />}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            margin: '0 auto 50px',
            maxWidth: 350,
          }}
        >
          <Grid container spacing={4}>
            <Grid alignSelf="flex-end" item xs={6}>
              <Button
                onClick={() => navigate(-1)}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid alignSelf="flex-end" item xs={6}>
              <Button
                onClick={handleSubmit(onSubmit)}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ResourceForm;
