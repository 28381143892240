import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResListQueryModel,
  ResourceCategoryModel,
} from '../../models/resource/resource.model';
import { Entity } from '../../constants/api';
import { FirestoreApi } from '../../service/firestoreApi';

export const getCategories = createAsyncThunk('resource/getCategories', async () => {
  return FirestoreApi.getAll<ResourceCategoryModel>(Entity.CATEGORY);
});

interface ResourceState {
  categories: ResourceCategoryModel[] | null;
  resListQuery: ResListQueryModel;
}

const initialState: ResourceState = {
  categories: null,
  resListQuery: {
    page: 0,
    pageSize: 10,
  },
};

export const resource = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    resListSetQuery: (state, { payload }) => {
      state.resListQuery = { ...state.resListQuery, ...payload };
    },
  },

  extraReducers: builder => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      if (action.payload) {
        state.categories = action.payload;
      }
    });
  },
});

export const { resListSetQuery } = resource.actions;

export default resource.reducer;
