export enum Entity {
  CATEGORY = 'in-categories',
  RESOURCE = 'in-resources',
}

// export enum EntityProd {
//   CATEGORY = 'in-categories',
//   RESOURCE = 'in-resources',
// }

export enum ApiCode {
  PERMISSION_DENIED = 'permission-denied',
}
