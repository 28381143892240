import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export const PRIMARY_COLOR = '#4AA6CE';

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(102,102,102,0.6)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          height: 45,
          borderRadius: 15,
        },
        text: {
          // color: PRIMARY_COLOR,
          // textTransform: 'none',
        },
      },
    },
  },
  palette: {
    text: {
      primary: '#666',
    },
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
