import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user';
import resourceReducer from './resource/resource';

export const store = configureStore({
  reducer: {
    user: userReducer,
    resource: resourceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
