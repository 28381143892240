import { useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../global';
import { getCategories, resListSetQuery } from '../../store/resource/resource';
import { ResListQueryModel } from '../../models/resource/resource.model';

export const useGetResCategories = () => {
  const categories = useAppSelector(state => state.resource.categories);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (!categories) {
      dispatch(getCategories());
    }
  }, []);

  return { categories };
};

export const useResListQuery = () => {
  const dispatch = useAppDispatch();
  const query = useAppSelector(state => state.resource.resListQuery);

  const setQuery = (query: ResListQueryModel) => {
    dispatch(resListSetQuery(query));
  };

  return {
    setQuery,
    query,
  };
};
