import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { ApiStatus } from '../models/global';
import {
  CreateResourceApiModel,
  ResourceCountModel,
  ResourceCreateModel,
  ResourceModel,
  UpdateResourceApiModel,
} from '../models/resource/resource.model';
import { filterResourceByOriginID } from '../helpers/resourceHelpers';
import { PACKAGE_TYPE } from '../constants';
import { db } from './firebase.config';
import { ApiCode, Entity } from '../constants/api';
import { store } from '../store/store';
import { fetchSignOut } from '../store/user/user';
import { toast } from 'react-toastify';

export class FirestoreApi {
  static handleError(e: any) {
    if (ApiCode.PERMISSION_DENIED) {
      store.dispatch(fetchSignOut());
    } else {
      toast.error(e);
    }
    throw Error(e);
  }

  static async getAll<T>(entity: Entity): Promise<T[] | undefined> {
    try {
      const querySnapshot = await getDocs(collection(db, entity));
      const data: T[] = [];
      querySnapshot.forEach(doc => {
        let item = doc.data();
        item.id = doc.id;
        data.push(item as T);
      });
      return data;
    } catch (e) {
      FirestoreApi.handleError(e);
    }
  }

  static async getOne<T>(entity: Entity, id: string): Promise<T | undefined> {
    try {
      const docRef = doc(db, Entity.RESOURCE, `${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data() as T;
      } else {
        return undefined;
      }
    } catch (e) {
      FirestoreApi.handleError(e);
    }
  }

  static async create<T>(entity: Entity, data: T): Promise<ApiStatus> {
    console.log('CREATE');
    try {
      await addDoc(collection(db, entity), data);
      return true;
    } catch (e) {
      FirestoreApi.handleError(e);
    }
  }

  static async update<T>(entity: Entity, id: string, data: T): Promise<ApiStatus> {
    console.log('UPDATE');
    try {
      await setDoc(doc(db, entity, id), data);
      return true;
    } catch (e) {
      FirestoreApi.handleError(e);
    }
  }

  static async getResources(): Promise<ResourceModel[] | undefined> {
    let data = await FirestoreApi.getAll<ResourceModel>(Entity.RESOURCE);
    if (data) {
      data = filterResourceByOriginID(data);
      return data;
    }
  }

  static async getResourcesByParentId(id: number): Promise<ResourceModel[] | undefined> {
    try {
      const resourcesRef = collection(db, Entity.RESOURCE);
      const q = query(resourcesRef, where('parentResource', '==', id));
      const querySnapshot = await getDocs(q);
      let data: ResourceModel[] = [];
      querySnapshot.forEach(doc => {
        let item = doc.data();
        item.id = doc.id;
        data.push(item as ResourceModel);
      });
      data = filterResourceByOriginID(data);
      return data;
    } catch (e) {
      FirestoreApi.handleError(e);
    }
  }

  static async getAllSingleResources() {
    const resourcesRef = collection(db, Entity.RESOURCE);
    const q = query(
      resourcesRef,
      where('docType', '!=', PACKAGE_TYPE),
      where('parentResource', '==', 0),
    );
    const querySnapshot = await getDocs(q);

    let data: ResourceModel[] = [];
    querySnapshot.forEach(doc => {
      let item = doc.data();
      item.id = doc.id;
      data.push(item as ResourceModel);
    });
    data = filterResourceByOriginID(data);
    return data;
  }

  static async createResource({ data, callback, packageItems }: CreateResourceApiModel) {
    console.log('CREATE!', data);
    try {
      const count = await FirestoreApi.getOne<ResourceCountModel>(
        Entity.RESOURCE,
        'resource-count',
      );
      if (count) {
        const increased = count?.['last-resource'] + 1;
        data.originalID = increased;
        const id = `${increased}_${data.title.split(' ').join('_')}`;
        const status = await FirestoreApi.update<ResourceCreateModel>(
          Entity.RESOURCE,
          id,
          data,
        );

        if (status) {
          if (packageItems) {
            const parentId = data.originalID;
            const batch = writeBatch(db);
            packageItems.forEach(packageItem => {
              const resourceRef = doc(db, Entity.RESOURCE, packageItem.id);
              batch.update(resourceRef, { parentResource: parentId });
            });
            await batch.commit();
          }
          callback && callback();
          FirestoreApi.update(Entity.RESOURCE, 'resource-count', {
            'last-resource': increased,
          });
        }
      }
    } catch (e) {
      FirestoreApi.handleError(e);
    }
  }

  static async updateResource({
    data,
    removedItemIds,
    packageItems,
    id,
  }: UpdateResourceApiModel): Promise<ApiStatus> {
    console.log('packageItems', packageItems);
    console.log('removedItemIds', removedItemIds);
    try {
      const status = await FirestoreApi.update<ResourceCreateModel>(
        Entity.RESOURCE,
        id,
        data,
      );

      if (status) {
        const isPackage = data.docType === PACKAGE_TYPE;
        const batch = writeBatch(db);
        if (isPackage) {
          const parentId = data.originalID;
          if (packageItems) {
            packageItems.forEach(packageItem => {
              const resourceRef = doc(db, Entity.RESOURCE, packageItem.id);
              batch.update(resourceRef, { parentResource: parentId });
            });
          }
        } else {
          if (packageItems) {
            packageItems.forEach(packageItem => {
              const resourceRef = doc(db, Entity.RESOURCE, packageItem.id);
              batch.update(resourceRef, { parentResource: 0 });
            });
          }
        }
        if (removedItemIds.length) {
          removedItemIds.forEach(id => {
            const resourceRef = doc(db, Entity.RESOURCE, id);
            batch.update(resourceRef, { parentResource: 0 });
          });
        }
        await batch.commit();
        return true;
      }
    } catch (e) {
      FirestoreApi.handleError(e);
    }
  }

  static async deleteResources(resources: ResourceModel[]): Promise<ApiStatus> {
    console.log('DELETE RESOURCES');
    try {
      const batch = writeBatch(db);
      const resourcePackages: ResourceModel[] = [];

      resources.forEach(resources => {
        if (resources.docType === PACKAGE_TYPE) {
          resourcePackages.push(resources);
        }
        batch.delete(doc(db, Entity.RESOURCE, resources.id));
      });

      if (resourcePackages.length) {
        const f = async (index: number) => {
          if (index === resourcePackages.length) {
            return;
          }
          const children = await FirestoreApi.getResourcesByParentId(
            resourcePackages[index].originalID,
          );
          if (children) {
            children.forEach(child => {
              const resourceRef = doc(db, Entity.RESOURCE, child.id);
              batch.update(resourceRef, { parentResource: 0 });
            });
          }
          await f(index + 1);
        };
        await f(0);
      }
      await batch.commit();
      return true;
    } catch (e) {
      FirestoreApi.handleError(e);
    }
  }
}
