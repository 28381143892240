import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from './firebase.config';
import { SignInModel, UserModel } from '../models/user/user.model';

export class AuthApi {
  static async signIn({ email, password }: SignInModel): Promise<UserModel> {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user: any = userCredential.user;
    return {
      accessToken: user.accessToken,
      email: user.email,
    };
  }

  static signOut() {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      });
  }
}
